<template>
  <div>
    <div class="float_right">
      <a href="https://www11.53kf.com/m.php?cid=72651991&kf_sign=jgzNzMTY2NkxNDE1Mjk1MDQ5ODAwMTE3MjY1MTk5MQ%253D%253D&tfrom=61&style=1&arg=10651991"><img :src="oss+'kefu.png'" class="kefu"/></a>
    </div>
    <div class="detail" v-if="detail.box_id && create_status==0">
      <div class="gimg">
        <img :src="detail.rewardPrize" width="100%"/>
        <img :src="detail.prizeGoodsImg" width="100%" class="f"/>
      </div>
      <div class="box">
        <div class="tag">
          <van-tag type="primary" color="#e8323c" text-color="#fff" size="large">享开盒子</van-tag>
          <van-tag plain type="primary" color="#e8323c" text-color="#e8323c" size="large">最高抽</van-tag>
          <van-tag plain type="primary" color="#e8323c" text-color="#e8323c" size="large">国行正品苹果手机</van-tag>
        </div>
        <div class="title">{{detail.prizeGoodsName}}</div>
        <van-row type="flex" justify="space-between" align="bottom" class="desc">
          <van-col class="price">开盒价 ¥<span>{{detail.price}}</span></van-col>
          <van-col>
            <van-row type="flex" :gutter="10">
              <van-col class="flicon">限时福利</van-col>
              <van-col>
                <van-row type="flex" align="bottom" class="count-down">
                  <van-col>还剩</van-col>
                  <van-col><van-count-down :time="time" /></van-col>
                </van-row>
              </van-col>
            </van-row>
          </van-col>
        </van-row>
        <van-row type="flex" justify="space-between" align="center" class="order_pay">
          <van-col class="price">¥<span>{{detail.price}}</span>开盒价</van-col>
          <van-col class="btn" @click="payorder">提交订单</van-col>
        </van-row>


        <div class="font-color-red mt10"><van-icon name="warning" /> 好物机会难得，立即提交订单</div>
        <div class="tips"><van-icon class-prefix="icon" name="ios"/>苹果 |该最高抽得奖品，100%官方正品</div>
      </div>

      <div class="payList">
        <van-radio-group v-model="json.pay_way">
          <van-cell-group>
            <van-cell v-for="item in payList" :key="item.key">
              <template #icon><img :src="item.img" height="24"/></template>
              <template #title>
                <van-row type="flex" align="center" :gutter="2">
                  <van-col>{{item.name}}</van-col>
                  <van-col><van-tag plain type="primary" color="#e8323c" text-color="#e8323c">首单随机立减，最高至免单</van-tag></van-col>
                </van-row>
              </template>
              <template #right-icon>
                <van-radio :name="item.payWay"></van-radio>
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
      </div>

      <van-cell-group class="mobile">
        <van-field v-model="json.phone" label="开盒手机号" input-align="right" :border="false" ref="phone"/>
        <div class="text">注意：手机号填写错可能导致订单无法发货！</div>
      </van-cell-group>

      <div class="prize">
        <div class="text1">该盲盒内全部奖品及资质：</div>
        <div class="text2 mt5"><van-tag plain type="primary" color="#e8323c" text-color="#e8323c" size="medium">传说款概率0.01%、史诗款概率1%、稀有款概率32.5%、普通款概率66. 45%</van-tag></div>
        <div class="goodlist">
          <van-row type="flex">
            <van-col class="qualifications">
              <div><img :src="oss+'qualifications.jpg'"/></div>
              <div class="name">企业资质</div>
            </van-col>
            <van-col v-for="item in goodList" :key="item.id">
              <div><img :src="item.image" width="100%"/></div>
              <div class="name">{{item.goods_name}}</div>
              <div class="other">¥{{item.coin_price}}<!--<van-tag type="primary" color="#929292" text-color="#fff" >共{{item.stock}}个</van-tag>--></div>
            </van-col>
          </van-row>
        </div>
      </div>
      <rule/>
      <foot/>
    </div>

    <div class="pay_status" v-if="create_status==1">
      <div class="success" v-if="paysuccess==1">
        <div><van-icon name="checked" size="120" color="#07c160"/></div>
        <div class="h4">支付成功</div>
        <div class="mt10 count-down">
          <van-count-down :time="paytime" @finish="finishPay">
            <template #default="timeData">
              <span class="block">{{ timeData.seconds }}</span>
              <span>S 后跳转</span>
            </template>
          </van-count-down>
        </div>
      </div>
      <div class="fail" v-if="paysuccess==0">
        <div class="h4">请在新打开的支付平台页进行支付</div>
        <div class="mt40"><van-button type="primary" color="#e9333f" block @click="onSubmitPay(1)">我已支付，立即升级</van-button></div>
        <div class="mt20"><van-button type="primary" color="#e9333f" block @click="onSubmitPay(0)">支付失败，重新支付</van-button></div>
      </div>
    </div>

    <van-popup v-model="recommendModal" :style="{width:'80%',backgroundColor:'transparent'}" @closed="onShowRecommend" closeable close-icon="clear">
      <template v-for="(item,key) in advList">
      <div :key="key" :class="'recommend_'+item.type" v-if="key==recommendType">
        <div class="title">{{item.title}}</div>
        <div class="info" v-if="item.type==1">
          <div class="img"><img :src="item.img"/></div>
          <div class="name">{{item.desc}}</div>
          <div class="price">秒杀价：¥{{item.discount_price}}</div>
        </div>
        <div class="info" v-if="item.type==2">
          <div class="img"><img :src="item.img"/></div>
        </div>
        <template v-if="item.type==3">
          <van-count-down :time="60000" @finish="()=>{this.recommendModal = false}">
            <template #default="timeData">
              剩余时间：{{ timeData.seconds }} 秒
            </template>
          </van-count-down>
          <div class="info">
            <div class="img"><img :src="item.img"/></div>
            <div class="name">{{item.desc}}</div>
            <div class="old_price">开盒价：¥{{item.price}}</div>
            <div class="price">折后价：¥{{item.discount_price}}</div>
          </div>
        </template>
        <div class="btn"><a @click="advClick(item)">{{item.btn_text}}</a></div>
      </div>
      </template>
    </van-popup>
  </div>
</template>
<script>
  import {isWeixin} from '@/utils/utils'
  import {initCloud} from '@wxcloud/cloud-sdk'
  import rule from '@/components/rule'
  import foot from '@/components/footer'
  import {GetPayConfig,GetBoxInfo,CreateOrder,GetOrderStatus} from './api/api'
  export default {
    name:'taw',
    components: {rule,foot},
    data () {
      return {
        type:'taw',
        oheadimg:this.oss+'header.png',
        time:15 * 60 * 60 * 1000,
        payList:[],
        goodList:[],
        detail:{},
        json:{
          tag:'taw',
          pay_way:0,
          order_sn:'',
          phone:'',
          url:location.href,
          box_id:''
        },
        a_oId:'',
        create_status:0,
        paysuccess:0,
        paytime:3 * 1000,

        recommendModal:false,
        recommendType:0,
        count:0,
        timer:null,
        isWeixin:false,
        openid:'',
        advList:[]
      }
    },
    created(){
      const {a_oId,phone,openid} = this.$route.query
      this.a_oId=a_oId==undefined?undefined:a_oId
      this.openid=openid==undefined?'':openid
      this.json.phone=phone==undefined?'':atob(phone)
      this.isWeixin = isWeixin()?1:0
      // this.isWeixin = 1
      if(this.isWeixin){
        if(this.openid == ''){
          this.getOpenId()
        }
        this.json.pay_way = 14
      }
    },
    computed:{
    },
    watch:{
    },
    mounted(){
      if(sessionStorage.getItem('order_info')){
        let order_info = JSON.parse(sessionStorage.getItem('order_info'))
        this.json={
          ...this.json,
          ...order_info
        }
        this.findOrderStatus(this.json.order_sn)
      }
      this.findpayConfig()
    },
    methods:{
      initCloud,
      getOpenId(){
        const redirect_url = location.href
        window.location.href = 'https://pay2.senhuo.cn/pay/WechatOpenId.php?redirect_url=' + encodeURIComponent(redirect_url)
      },
      findpayConfig(){
        GetPayConfig({type:this.type,tag:(this.isWeixin?'wechat':'alipay')}).then(result => {
          if(result.data.code==1){
            const {payConfig,box_id,price,prizeGoodsImg,prizeGoodsName,rewardPrize,goodsList,android_download_url,ios_download_url} = result.data.data
            this.payList=payConfig
            this.json.pay_way=this.payList[0].payWay
            this.detail = {box_id,price,prizeGoodsImg,prizeGoodsName,rewardPrize,android_download_url,ios_download_url}
            this.json.box_id=box_id
            this.findDetail(box_id)
            if(sessionStorage.getItem('order_info')) {
              this.findAdv(goodsList)
            }
            // sessionStorage.removeItem('order_info')
          }else{
            this.$toast.fail(result.data.msg)
          }
        })

      },
      findDetail(box_id){
        GetBoxInfo({id:box_id}).then(result => {
          if(result.data.code==1){
            const {data} = result.data
            this.goodList=data
          }else{
            this.$toast.fail(result.data.msg)
          }
        })

      },
      findAdv(res){
        this.recommendModal = true
        this.advList=res
      },
      advClick(record){
        if(record.type==1){
          var u = navigator.userAgent
          if(u.indexOf('Android') > -1 || u.indexOf('Adr') > -1){//Android
            location.href=this.detail.android_download_url
          }else if(!!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)){//ios
            location.href=this.detail.ios_download_url
          }
        }else{
          this.payorder(record.id)
        }
      },
      payorder(box_id){
        if(this.json.phone==''){
          this.$toast('请输入您的开盒手机号')
          this.$refs.phone.focus()
          return false
        }else if(!/^1[3456789]\d{9}$/.test(this.json.phone)){
          this.$toast('请输入正确的手机号码')
          this.$refs.phone.focus()
          return false
        }
        this.$toast.loading({
          message: '正在提交订单...',
          forbidClick: true,
        })
        let pay=this.payList.find(item=>item.payWay == this.json.pay_way)
        const {pwyAccount} = pay
        let order_sn = (this.a_oId==''||this.a_oId==undefined)?this.getordernum(6):this.a_oId
        this.json={
          ...this.json,
          box_id:(box_id==undefined?this.json.box_id:box_id),
          account:pwyAccount,
          order_sn:order_sn
        }
        this.getCreateOrder(this.json)
      },
      getCreateOrder(res){
        CreateOrder({...res,openid:(this.isWeixin?this.openid:undefined),pay_tag:(this.isWeixin?'wechat':'alipay')}).then(result => {
          if(result.data.code==1){
            sessionStorage.setItem('order_info',JSON.stringify(this.json))
            const {data} = result.data
            let pay_way = res.pay_way
            if(pay_way==14){
              location.href=data
              // this.payWeChat(data)
            }else if(pay_way==16){
              this.Alipay(data)
            }

          }else{
            this.$toast.fail(result.data.msg)
          }
        })
      },
      async payWeChat(orderId){
        let c1 = new this.initCloud().Cloud({
          // 必填，表示是未登录模式
          identityless: true,
          // 资源方 AppID
          resourceAppid: 'wxdada9bc45300a359',
          // 资源方环境 ID
          resourceEnv: 'cloud1-3gua9rkm875bd364',
        })

        await c1.init()
        const res =  await c1.callFunction({
          name: 'public',
          data: {
            action: 'getUrlScheme',
            path:'pages/index/index', // 想跳转的小程序路径
            orderId:orderId, // 预下单单号
          },
        }).then((res)=>{
          let result = res.result
          if(result.errCode==0){
            location.href = result.openlink
          }
        })
      },
      Alipay(res){
        const div = document.createElement("formdiv")
        div.innerHTML = res
        document.body.appendChild(div)
        document.forms["pay"].setAttribute("target", "_self")
        document.forms["pay"].submit()
        div.remove()
      },
      getordernum(num){
        let random = Math.floor((Math.random()+Math.floor(Math.random()*9+1))*Math.pow(10,num-1))
        return new Date().getTime().toString()+random.toString()
      },
      findOrderStatus(order_sn){
        this.timer = setInterval(() => {
          if(this.count>=3){
            if(this.recommendType>=this.advList.length && !this.recommendModal){
              this.create_status=1
            }
            clearInterval(this.timer)
            return false
          }
          this.count=this.count+1
          GetOrderStatus({order_sn:order_sn}).then(result => {
            const {code} = result.data
            if(code==1){
              this.paysuccess = code
              clearInterval(this.timer)
            }else{
              this.findOrderStatus(order_sn)
            }
          })
        },3*1000)

      },
      finishPay(){
        location.href = 'https://api.cyrai.cn/xd/paysuccess.html?orderNo='+this.json.order_sn
      },
      onSubmitPay(status){
        switch (status) {
          case 1:
            location.href = 'https://api.cyrai.cn/xd/paysuccess.html?orderNo='+this.json.order_sn
            break;
          case 0:
            this.getCreateOrder(this.json)
            break;
        }
      },
      onShowRecommend(){
        if(this.recommendType<this.advList.length-1){
          this.recommendType=this.recommendType+1
          this.recommendModal=true
        }else{
          if(this.count>=3){
            this.create_status=1
          }
          this.recommendModal=false
        }
      }
    }
  }
</script>
<style lang="less">
  @import "./css/taw.less";
</style>
