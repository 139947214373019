<template>
  <div class="footer">
    <p>客服电话：4006178158</p>
     <p>南通有钻贸易有限公司</p>
     <p>青岛德汇利通信息科技有限公司</p>
    <p><img src="https://jinxi2.oss-cn-shenzhen.aliyuncs.com/tuia/netbg.png" width="20"/>苏ICP备2021030249号-1</p>

  </div>
</template>
<script>
  export default {
    components: {},
    data () {
      return {
      }
    },
    created(){

    },
    computed:{
    },
    watch:{
    },
    mounted(){

    },
    methods:{

    }
  }
</script>
<style lang="less">
</style>
