const HTTP_URL=process.env.VUE_APP_API
import {request, METHOD} from '@/utils/request'

export async function GetPayConfig(params) {
  return request(HTTP_URL+'api/getConfig', METHOD.POST,params)
}

export async function GetBoxInfo(params) {
  return request(HTTP_URL+'index/getBoxById', METHOD.GET,params)
}
export async function CreateOrder(params) {
  return request(HTTP_URL+'newboxapi/createOrder', METHOD.POST,params)
}
export async function GetOrderStatus(params) {
  return request(HTTP_URL+'newboxapi/getOrderStatus', METHOD.POST,params)
}
